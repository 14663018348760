.form-and-image-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // @media only screen and (min-width: 950px) {
  //   width: 90%;
  //   flex-direction: row;
  //   align-items: flex-start;
  // }
}
.form-and-image-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 90%;
  min-height: 400px;
  color: white;
  font-size: 30px;
  padding-top: 30px;
}
.form-and-image-form-container {
  width: 90%;
  background-color: rgb(245, 245, 245);
  padding: 33px 0;
  margin-bottom: 30px;
}

.form-and-image-form {
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: column;

  h2 {
    color: #27282c;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Raleway';
    margin: 20px 0 40px 0;
  }
}
