.state-card-container {
  background-color: rgba(0, 0, 0, 0.485);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.state-card {
  background-color: #fff;
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  p {
    color: black !important;
  }
}
