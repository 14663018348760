.admin-users-page-container {
  width: 90%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 30px;
    font-weight: bold;
  }
}
.admin-users-table-container {
  text-align: center;
  width: 100%;
  label {
    display: inline;
  }
}

.table td,
.table th {
  vertical-align: baseline !important;
  text-align: center;
}
.admin-users-buttons-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.admin-users-add-user-container {
  z-index: 10;
  border-radius: 5px;
  padding: 30px;
  width: 100%;
  box-shadow: rgb(235, 235, 235) 0px 3px 9px 2px;
  margin-bottom: 30px;
}
