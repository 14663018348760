/*  Fonts */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap');
* {
  box-sizing: border-box;
}
body {
  color: #212121 !important;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Raleway';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.4s;
}
ul {
  list-style: none;
}
a {
  text-decoration: none !important;
  font-family: 'Raleway';
  color: #0db539;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0 !important;
}
p {
  font-family: 'Raleway';
}
