.about-page {
  padding-top: 74px;
  background: rgb(245, 245, 245);
}
// Hero image
.about-image-container {
  position: relative;
  background-image: url('../../assets/images/hero-about.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
  display: flex;
}
.about-image-filter {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.479);
}
.about-divider {
  width: 40%;
  max-width: 150px;
  border-bottom: 4px solid white;
  margin-bottom: 30px;
  p {
    color: #0db539;
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}
.about-text-container {
  z-index: 10;
  width: 90%;
  color: #ffffff;
  margin: auto;

  h1 {
    font-size: 48px;
    font-weight: 500;
    margin: 0 0 20px 0;
  }
}
.about-sub-text {
  font-size: 18px;
  font-weight: 500;
}

// Content
.about-page-content {
  display: flex;
  justify-content: center;

  &-container {
    width: 100%;
  }
}

.about-page-header-content {
  margin: 75px auto;
  width: 90%;
}

.about-page-header-title {
  color: #0db539;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0;
}

.about-page-header-text {
  font-size: 16px;
}

// Carousel
.about-page-carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
}

// Funds section
.about-page-funds-section {
  background-color: #fff;
}
.about-page-funds-container {
  margin: 40px 0;
  width: 90%;
  margin: 40px auto 0 auto;
  padding: 20px 20px 50px 20px;
  @media only screen and (max-width: 590px) {
    padding: 20px 10px;
  }
}

.about-page-funds-sub-title {
  text-transform: uppercase;
  font-size: 20px;
  font-family: 'Raleway';
  font-weight: 500;

  @media only screen and (min-width: 590px) {
    font-weight: 700;
  }
}

.about-carousel-text-title {
  @media only screen and (max-width: 590px) {
    margin-left: 20px !important;
  }
}
.about-carousel-text-ul p {
  text-align: justify !important;
}
