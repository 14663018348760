.contact-form-container {
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
}
.contact-form-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > * {
    width: 40%;
    margin: 10px;
  }
}
