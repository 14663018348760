.admin-donations-graph {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -40px;
  margin-bottom: 40px;
}
.admin-donations-inputs {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
.admin-donations-input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
input {
  padding: 5px;
  border-radius: 5px;
}
