.searchbox-container {
  width: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  @media only screen and (max-width: 450px) {
    width: 310px;
  }
}
.searchbox-input {
  padding: 10px 15px;
  border-radius: 3px;
  border: 1px solid rgb(192, 192, 192);
  font-size: 16px;
  width: 100%;
}
.searchbox-button {
  position: relative;
  display: inline-block;
  background-color: #0db539;
  color: #ffffff;
  transition: all 500ms ease;
  padding: 7px 15px 9px 15px;
  border: none;
  font-size: 20px;
  border-radius: 3px;
  margin-left: 10px;

  &:hover {
    background: #27282c;
  }
}
