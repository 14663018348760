// Hidden
.hidden {
  display: none;
}

// Margin

.marginTop-50 {
  margin-top: -50px !important;
}
.marginTop-40 {
  margin-top: -40px !important;
}
.marginTop-30 {
  margin-top: -30px !important;
}
.marginTop-20 {
  margin-top: -20px !important;
}
.margin0Center {
  margin: 0 auto;
}
.margin20Center {
  margin: 20px auto;
}
.margin40Center {
  margin: 40px auto;
}
.marginBottom10 {
  margin-bottom: 10px !important;
}
.marginBottom20 {
  margin-bottom: 20px !important;
}
.marginBottom30 {
  margin-bottom: 30px !important;
}
.marginBottom50 {
  margin-bottom: 50px !important;
}
.marginBottom40 {
  margin-bottom: 40px !important;
}
.marginBottom-30 {
  margin-bottom: -30px !important;
}
.marginTop-10 {
  margin-top: -10px !important;
}
.marginTop10 {
  margin-top: 10px !important;
}
.marginTop20 {
  margin-top: 20px !important;
}
.marginTop30 {
  margin-top: 30px !important;
}
.marginTop40 {
  margin-top: 40px !important;
}
.marginTop50 {
  margin-top: 50px !important;
}
.marginTopBottom10 {
  margin: 40px 0 !important;
}
.marginTopBottom20 {
  margin: 40px 0 !important;
}
.marginTopBottom30 {
  margin: 40px 0 !important;
}
.marginTopBottom40 {
  margin: 40px 0 !important;
}
// Padding
.paddingBottom10 {
  padding-bottom: 10px !important;
}
.paddingBottom20 {
  padding-bottom: 20px !important;
}

.paddingBottom30 {
  padding-bottom: 30px !important;
}

.paddingBottom40 {
  padding-bottom: 40px !important;
}
.paddingTop10 {
  padding-top: -10px !important;
}
.paddingTop20 {
  padding-top: 20px !important;
}
.paddingTop30 {
  padding-top: 30px !important;
}
.paddingTop40 {
  padding-top: 40px !important;
}
.paddingTop74 {
  padding-top: 74px !important;
}
.paddingLeft20 {
  padding-left: 20px !important;
}

// Text
.header-small-title {
  text-align: justify;
  text-transform: uppercase;
  color: #0db539;
  font-size: 22px;
  @media only screen and (max-width: 550px) {
    text-align: center !important;
    width: 270px !important;
  }
}
.header-small-title2 {
  text-align: justify;
  text-transform: uppercase;
  color: #0db539;
  font-size: 22px;
}
.fontSize18 {
  font-size: 18px;
}
.fontSize20 {
  font-size: 20px;
}
.fontSize24 {
  font-size: 24px;
}

// Colors
.color-black {
  color: black !important;
}
.color-green {
  color: rgba(13, 181, 57, 1);
}
.color-red {
  color: rgba(250, 87, 90, 1);
}
.color-blue {
  color: rgba(36, 124, 255, 1);
}
.cursorPointer {
  cursor: pointer;
}
// Container
.width70 {
  width: 70% !important;
}
.width80Max900 {
  width: 55%;
  max-width: 900px;
  margin: 0 auto;
}
.container100LightShadow {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ced4da;
  box-shadow: rgb(235, 235, 235) 0px 3px 9px 2px;
}
.page-content-width-100 {
  width: 100%;
}
.basic-container-100-row {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.basic-container-100 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.basic-container-90 {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.basic-container-90-row {
  margin: 0 auto;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.basic-container-80 {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.basic-container-70 {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.basic-container-60 {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.basic-container-50 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1450px) {
    width: 70%;
  }
  @media only screen and (max-width: 990px) {
    width: 90%;
  }
}
// Flex-containers
.container-display-flex-row {
  display: flex;
  flex-direction: row;
}
.container-display-flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media only screen and (max-width: 840px) {
    justify-content: center;
  }
}

.container-display-flex-col {
  display: flex;
  flex-direction: column;
}
.container-display-flex-col-align-center {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}

// Texts
.align-justify {
  text-align: justify !important;
}
.align-justifyLeftOnPhones {
  text-align: justify !important;

  @media only screen and (max-width: 550px) {
    text-align: left !important;
  }
}
.medium-p {
  font-size: 18px;
}
.boldText {
  font-weight: bold;
}

// Form
.helper-text {
  font-size: 12px;
}
.alert-helper {
  font-size: 12px;
  color: red;
}

// Effects
.show-opacity {
  display: initial !important;
  opacity: 1 !important;
}

// Loader
.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #0db539; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(330deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.react-bootstrap-table table {
  table-layout: auto;
}
