.media-article-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin: 50px;
  }
}
.media-article-content {
  width: 80%;
}
