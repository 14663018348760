.accounting-page {
  margin: 30px auto;
  width: 90%;
  text-align: center;
  h3 {
    font-size: 30px;
    font-weight: bold;
  }
}
.accounting-page-button-container {
  margin: 10px 0 50px 0;
}
