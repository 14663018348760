.about-carousel-container {
  display: flex;
  flex-direction: row;
  text-align: center;

  @media only screen and (max-width: 1305px) {
    flex-direction: column;
    width: 100%;
  }
  @media only screen and (max-width: 990px) {
    width: 100%;
  }
}
.sc-bdfBwQ {
  display: none;
}
.sc-dlfnbm {
  margin: 0 !important;
}
.carousel-partner-arrow-container {
  :hover {
    color: #0db539;
  }
}
