.carousel-partner-container {
  text-align: center;
  margin: 30px auto;
  @media only screen and (max-width: 550px) {
    width: 95%;
  }
}
.carousel-partner-buttons-container {
  @media only screen and (max-width: 550px) {
    width: 70%;
    margin-top: 15px;
  }
  width: 30%;
  margin: 30px auto 0 auto;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-evenly;
}
.carousel-partner-arrow-container {
  background-color: #f6f6f6;
  border: 1px solid rgba(175, 175, 175, 0.363);
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
}
.carousel-partner-title {
  color: #0db539;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  &-2 {
    margin: 20px 0 50px 0;
    color: #27282c;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    @media only screen and (max-width: 550px) {
      font-size: 20px;
    }
  }
}
.carousel-title-2-span {
  font-weight: 600;
}

.rec.rec-arrow {
  visibility: hidden;
}
.rec.rec-pagination {
  visibility: hidden;
}

.partner-name {
  font-family: 'Raleway';
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
  color: #0db539 !important;
  opacity: 0;
  transition: all 0.3s;
}
.partner-logo {
  &:hover .partner-name {
    opacity: 1 !important;
  }
}
