.hero-carousel {
  h3 {
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 1px;
    @media only screen and (max-width: 950px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 630px) {
      font-size: 20px;
    }
  }
  p {
    font-size: 20px;
    @media only screen and (max-width: 950px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 630px) {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 550px) {
    h3 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
    }
  }
}
