.green-button-1 {
  position: relative;
  display: inline-block;
  background: #0db539;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;

  font-family: "raleway";
  padding: 10px 40px;
  transition: all 500ms ease;
  border: none;
  border-radius: 3px;
}
.blue-button-2 {
  position: relative;
  display: inline-block;
  background: #3298dc;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;

  font-family: "raleway";
  padding: 10px 40px;
  transition: all 500ms ease;
  border: none;
  border-radius: 3px;
}
.dark-grey-button-1 {
  position: relative;
  display: inline-block;
  background: #222;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;

  font-family: "raleway";
  padding: 10px 40px;
  transition: all 500ms ease;
  border: none;
  border-radius: 3px;
}
.blue-button-1 {
  position: relative;
  display: inline-block;
  background: #3298dc;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin: 0 20px;
  font-family: "raleway";
  padding: 5px 20px;
  transition: all 500ms ease;
  border: none;
  border-radius: 3px;
}
.red-button-1 {
  position: relative;
  display: inline-block;
  background: #f14668;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  font-family: "raleway";
  padding: 5px 20px;
  transition: all 500ms ease;
  border: none;
  border-radius: 3px;
}
.red-button-2 {
  position: relative;
  display: inline-block;
  background: #f14668;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-family: "raleway";
  padding: 10px 40px;
  transition: all 500ms ease;
  border: none;
  border-radius: 3px;
}
