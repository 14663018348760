.about-carousel-image {
  width: 50%;
  min-width: 470px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  background-image: url('../../assets/images/about-img-1.jpg');
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 1305px) {
    min-width: 100%;
    height: 400px;
  }

  &-text {
    margin-top: 60px;
    color: white;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: 'Raleway';
  }
}
