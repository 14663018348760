.admin-members-table-container {
  margin: 30px auto;
  width: 90%;
  text-align: center;
  h3 {
    font-size: 30px;
    font-weight: bold;
  }
}
.admin-users-buttons-container {
  margin: 20px 0 50px 0 !important;
  display: flex;
}
