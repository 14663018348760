.header-admin {
  z-index: 300000;
  background-color: #27282c;
  color: white;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  @media screen and(max-width: 550px) {
    padding: 0 20px;
  }
  p {
    margin: 0;
  }

  ul {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    li {
      font-size: 18px;
      a {
        color: white;
        &:hover {
          color: rgb(209, 209, 209);
        }
      }
    }
  }
}
.header-admin-nav {
  width: 60%;
}
.header-admin-logo {
  letter-spacing: 2px;
  width: 20%;
  a {
    padding: 8px 10px 10px 10px;
    border-radius: 5px;
    color: #0db539;
    &:hover {
      color: #0db539;
      background-color: rgb(62, 62, 62);
    }
  }
}
.header-nav-name {
  margin-left: 40px;
  width: 20%;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1060px) {
  .header-admin-nav {
    ul li {
      font-size: 16px;
    }
  }
  .header-nav-name {
    font-size: 16px;
  }
}
@media screen and (max-width: 992px) {
  .header-admin-nav {
    display: none;
  }
  .header-nav-name {
    display: none;
  }
}

// Sidebar
.header-admin-sidebar {
  z-index: 10000;
  width: 100vw;
  min-height: 500px;
  position: absolute;
  top: 75px;
  right: 0;
  background-color: #27282c;
  box-shadow: #343434 0px 9px 20px -6px;
  border-top: 1px solid rgba(115, 115, 115, 0.444);

  ul {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    padding: 10px 0;
    a {
      width: 100%;
      font-size: 20px;
      height: 50px;

      li {
        width: 100%;
        padding: 17px;
        padding-left: 50px;
        color: white;
        &:hover {
          background-color: #38383b;
          color: #0db539;
        }
      }
    }
  }
}
.hideAdminSidebar {
  top: -200vh !important;
}
.sub-member-nav {
  height: 50px !important;
  font-size: 18px !important;

  li {
    padding-left: 80px !important;
  }
}

// Dropdown
.dropbtn {
  font-size: 18px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;

  min-width: 230px;
  height: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  a {
    color: black !important;
  }
}

.dropdown-content a {
  border-radius: 5px;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: rgb(185, 185, 185);
}
.compta-dropdown {
  height: 150px;
}
