.admin-login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.admin-login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & img {
    width: 150px;
  }
}
.admin-login-form {
  margin-top: 20px;
  box-shadow: rgb(235, 235, 235) 0px 3px 9px 2px;
  border-radius: 5px;
  width: 250px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  label {
    font-weight: bold;
  }
}
