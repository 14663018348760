.form-control::placeholder {
  color: rgba(170, 170, 170, 0.61) !important;

}
.add-article-form {
  border-radius: 5px; 
  padding: 25px;
  box-shadow: -1px 0px 3px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: -1px 0px 3px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 0px 3px 0px rgba(0,0,0,0.75);

}


