.languageSelector-container {
  z-index: 10000;
  background-color: rgb(202, 202, 202);
  position: fixed;
  height: 40px;
  bottom: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  width: 100px;
  border-radius: 0;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}
.languageSelectorImg {
  width: 35px;
  height: 22px;
  cursor: pointer;
}
