.admin-dashboard-small-card {
  height: 170px;
  width: 170px;
  background-color: #555555;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 30px 30px 0;

  &-number {
    font-size: 36px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    margin: 0;
  }
  &-title {
    margin: 0;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: white;
    text-align: center;
  }
  @media screen and (max-width: 749px) {
    margin: 0 20px 30px 0;
  }
  @media screen and (max-width: 474px) {
    margin: 0 0px 30px 0;
  }
}
