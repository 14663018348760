.hero-top-image-container {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
  display: flex;
}

// Hero image
.hero-top-image-filter {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.479);
}
.hero-top-divider {
  width: 40%;
  max-width: 150px;
  border-bottom: 4px solid white;
  margin-bottom: 30px;
}
.hero-top-text-container {
  z-index: 10;
  width: 90%;
  color: #ffffff;
  margin: auto;

  h1 {
    font-size: 48px;
    font-weight: 500;
    margin: 0 0 20px 0;
  }
}
.hero-top-sub-text {
  font-size: 18px;
  font-weight: 500;
}
.marquee-hero {
  position: absolute;
  top: 74px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  width: 100%;
}
