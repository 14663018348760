.cookie-consent-card {
  position: fixed;
  width: 80%;

  bottom: 2%;
  left: 10%;
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 10px 1px;
}
.cookie-consent-btn-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 30px 0;
  a {
    text-decoration: underline !important;
  }
  @media screen and (max-width: 550px) {
    flex-direction: column;
    height: 100px;
  }
}
