.admin-media-buttons-container {
    width:70%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

}
.invisible-divider {
    height: 50px;
}