.admin-dashboard {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-container {
    margin-top: 50px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    margin-top: 50px;
    font-size: 28px;
    font-weight: normal;
    letter-spacing: 1px;
  }
  &-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  &-divider {
    margin: 50px 0;
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
  }
  &-button-container {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.admin-dashboard-chart {
  height: 300px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 230px;
}
.admin-dashboard-chart-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 50px;
}
.admin-dashboard-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

}
.admin-dashboard-chart-date {  
  width: 100%;
  height: 100px;
  margin-top: 50px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  & > input {
    width: 300px;
    padding: 5px;
    border-radius: 5px;
    margin: 10px;
  }
  & > label {
    margin-bottom: -10px;
  }
}