.container-contact-info {
  min-width: 235px;
  width: 50%;
  text-align: center;
  font-size: 18px;
  font-family: 'Raleway';
  @media only screen and (max-width: 840px) {
    margin-top: 40px;
  }
}
.container-contact {
  @media only screen and (max-width: 670px) {
    //
    justify-content: center;
  }
}
#header-contact {
  margin-top: 30px;
  text-align: center;
}
