// Animations
@keyframes blinking {
  0% {
    color: white;
  }
  50% {
    color: rgb(13, 181, 57);
  }
  80% {
    color: white;
  }
  100% {
    color: white;
  }
}

.header {
  position: fixed;
  width: 100%;
  height: 75px;
  z-index: 100;
  background-color: #27282c;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 5000;

  &-join-sub-menu {
    display: none;
    opacity: 0;
    background-color: #27282c;
    position: absolute;
    transform: translateY(66%);
    width: 300px;
    transition: all 0.4s;

    ul {
      width: 100% !important;
      padding-left: 0;
      display: flex;
      flex-direction: column !important;
      a {
        padding: 10px;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.341);
        li {
          width: 100%;
          text-align: start;
          padding: 10px 10px 10px 20px;
        }
      }
    }
  }

  &-container {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;

    &-logo {
      width: 10%;
    }

    ul {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & li {
        height: 100%;
        width: 14.23%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    a,
    p {
      margin: 0;
      cursor: pointer;
      color: white;
      font-weight: 700;
      font-family: 'Raleway';
      letter-spacing: 1px;
      font-size: 14px;
      text-align: center;
      &:hover {
        color: #0db539;
      }
    }
  }
}
.li-container {
  border-left: 1px solid #3d3e42;
  border-right: 1px solid #1a1b1f;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.li-container:last-of-type {
  border-right: 1px solid #3d3e42;
}
.li-active {
  color: #0db539 !important;
}
.li-active-bg {
  background-color: #0db539 !important;
}
.header-donation-button {
  background-color: rgb(13, 181, 57);
  margin-left: -30px;
  animation-name: blinking;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  font-weight: 600;
  font-size: 15px;
  font-family: 'Raleway';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  a {
    font-size: 15px;
  }
  p {
    &:hover {
      color: black;
    }
    margin: 20px 10px;
  }
  &:hover  {
    color: black;
  }
}
#header-donation-ul {
  background-color: rgb(13, 181, 57);
  color: white;

  & a {
    animation-name: blinking;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
  & a:hover {
    color: #27282c !important;
  }
}
.header-donation-button {
  & p {
    animation-name: blinking;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
  & p:hover {
    color: #27282c !important;
  }
}

//Navbar button
.navbar-toggle-button {
  z-index: 3500;
  cursor: pointer;
  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #e7e7e7;
    border-radius: 3px;
    transition: all 0.3s;
  }
}
// Navbar Animations Classes
.rotate-1 {
  transform: rotate(-45deg) translateX(-35%);
}
.rotate-2 {
  transform: translateX(500%);
}
.rotate-3 {
  transform: rotate(45deg) translateX(-40%);
}
.slide-left {
  display: initial;
  left: 0% !important;
}
// Media queries
@media only screen and (min-width: 993px) {
  .header-donation-button,
  .navbar-toggle-button {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .header-ul {
    display: none !important;
  }
  .header-container {
    justify-content: space-between !important;
  }
}
@media only screen and (max-width: 400px) {
  .header-donation-button p {
    margin: 20px 10px;
  }
}
