@import url('https://fonts.googleapis.com/css2?family=Amarante&display=swap');

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: #27282c;
  color: white;
  padding: 50px;
  @media only screen and (max-width: 550px) {
    justify-content: center;
  }
  h4 {
    font-family: 'Raleway';
    font-weight: 600;
  }
}
.footer-logo-container {
  margin-bottom: 40px;
  img {
    width: 150px;
  }

  p {
    font-size: 20px;
    font-weight: bold;
    color: #80e820;
    font-family: 'Amarante';
  }
}
.footer-logo-newsletter-container {
  text-align: center;
  width: 48%;
  min-width: 200px;
  margin-bottom: 40px;
  @media only screen and (max-width: 550px) {
    width: 100%;
  }
}
.footer-newsletter-container {
  p {
    color: white;
  }
}
.newsletter-input {
  padding: 10px 15px;
  border-radius: 3px;
  border: none;
}
.newsletter-button {
  position: relative;
  display: inline-block;
  background-color: #0db539;
  color: #ffffff;
  transition: all 500ms ease;
  padding: 5px 15px 7px 15px;
  border: none;
  font-size: 20px;
  border-radius: 3px;
  margin-left: 10px;

  &:hover {
    background: #27282c;
  }
}
.footer-links-socials-container {
  text-align: center;
  width: 48%;
  min-width: 200px;
  @media only screen and (max-width: 550px) {
    width: 100%;
  }
}
.footer-links-container {
  margin-bottom: 40px;

  padding: 0;
}
.footer-socials-container {
  h4 {
    margin-bottom: 20px;
  }
}
.footer-links-ul {
  width: 100%;
  padding: 0;

  li {
    margin: 15px 0;
  }

  a {
    color: white;
  }
}

.footer-socials-ul {
  width: 250px;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.footer-socials-image {
  width: 40px;
}
