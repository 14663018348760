/* Menu navbar */
#menu-section {
  width: 90%;
  position: fixed;
  justify-content: center;
  background-color: #27282c;
  margin-top: 75px;
  display: none;
  left: 50%;
  top: -100%;
  transform: translateX(-50%);
  z-index: 1000;
  transition: all 0.4s;
}
.menu-container {
  height: 50%;
  width: 100%;
  display: flex;

  transition: all 0.4s;
}
.menu-selection {
  height: 100%;
  width: 100%;
  font-size: 20px;
}

.menu-selection ul {
  height: 100%;
  display: flex;
  text-transform: uppercase;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding-left: 0px;

  a li,
  li {
    cursor: pointer;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    &:hover {
      background-color: #0db539;
    }
  }
}

.menu-selection ul li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid grey;
}

.show {
  display: initial !important;
  top: 0 !important;
}
.extend {
  height: 70% !important;
}
#menu-sub-ul {
  font-size: 15px;
  height: 180px;
  flex-direction: column;
  justify-content: space-between;
  text-transform: uppercase;
  display: none;
  margin: 0;
  padding: 0;
  transition: all 0.2s;
  font-size: 16px;
}
.show-sub-ul {
  display: flex !important;
}
