.editorClassName {
border: 1px solid grey;
border-radius: 0 0 3Px 3px;
min-height: 200px;
padding: 30px;
}
.rdw-editor-toolbar {
    margin: 0 ;
}
.wrapperClassName {
    width: 80%;
    margin: 0 auto; 
}
.text-editor-button-container {
    display: flex;
    justify-content: space-evenly;
    max-width: 500px;
    margin: 0 auto;
}