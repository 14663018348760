.actuality-thumbnail {
  width: 100%;
  max-width: 400px;
  a {
    color: #0db539;
    font-size: 16px;
    font-weight: 400;
  }
  @media only screen and (min-width: 500px) and (max-width: 888px) {
    width: 50%;
  }
  @media only screen and (min-width: 1334px) and (max-width: 1482px) {
    width: 25%;
  }
}
