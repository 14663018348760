.payment-container {
  padding-top: 80px;
  height: 100vh;
  width: 100vw;
  background-image: url('../../assets/images/hero-about.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.payment-info-container {
  background: rgba(255, 255, 255, 0.774);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 40%;
  min-width: 300px;
  height: 40%;
  min-height: 400px;
  padding: 10px;

  h2 {
    font-size: 34px;
  }
  p {
    font-size: 22px;
  }
}
