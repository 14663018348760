.admin-dashboard-big-card {
  height: 235px;
  width: 400px;
  background-color: #555555;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 30px 30px 0;

  @media screen and (max-width: 1612px) {
    height: 190px;
    width: 300px;
  }
  @media screen and (max-width: 1237px) {
    height: 180px;
    width: 200px;
  }
  @media screen and (max-width: 749px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 574px) {
    margin-right: 0px;
  }
  @media screen and (max-width: 499px) {
    margin-right: 0px;
  }

  &-price {
    font-size: 36px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    margin: 0;
  }
  &-title {
    margin: 0;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: white;
  }
}
