.official-documents-card {
  width: 80%;
  margin: 100px auto;
  box-shadow: 0px 4px 11px -1px #ececec;
  text-align: center;
  a {
    color: black;
  }

  @media only screen and (min-width: 990px) {
    width: 30%;
  }
  &:hover {
    .official-documents-card-icon-container {
      background-color: #0db539;
      img {
        filter: invert(100%);
      }
    }
    .official-documents-card-button-container a {
      color: #0db539;
    }
  }
}
.official-documents-card-icon-container {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border: 3px solid #0db539;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  transition: all 0.4s;

  img {
    width: 50px;
    filter: opacity(35%);
  }
}

.official-documents-card-text-container {
  color: #27282c;
  font-family: 'Raleway';
  transform: translateY(-25%);
  min-height: 150px;

  & > * {
    margin: 20px 0;
  }
  h4 {
    text-transform: uppercase;
    font-weight: 500;
  }
  @media only screen and (max-width: 990px) {
    padding: 0 20px;
  }
}
.official-documents-card-button-container {
  background-color: rgb(245, 245, 245);
  padding: 15px 0;

  a {
    border: none;
    background-color: transparent;
    outline: none;
    &:focus {
      outline: 0;
    }
    &:hover {
      color: #0db539;
    }
  }
}
