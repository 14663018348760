.official-documents-page {
  padding: 74px 0;
  background-color: #fff;

  a {
    color: #0db539;
  }
}

// Content
.official-documents-content {
  margin: 50px 0;
}
.official-documents-cards-container {
  @media only screen and (min-width: 990px) {
    display: flex;
    flex-direction: row;
  }
}
// Links section
.official-documents-links-container {
  width: 80%;
  margin: 0 auto;
}
.official-documents-links-1-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 200px;
}
.official-documents-links-2-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 200px;
}
