.card-text-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-text-content {
  background-color: #fff;
  min-height: 300px;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(235, 235, 235) 0px 3px 9px 2px;
  position: relative;
  text-align: justify;
  padding: 15px;
  border-radius: 5px;
}
.card-text-close {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 20px;
}
.card-text-text {
  margin-top: 20px;
}
