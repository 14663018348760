.syca-form {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 40%;
  min-width: 300px;
  height: 450px;
  padding: 20px;
  outline: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.644);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.label-input {
  input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.18);
  }
}

.input-disabled {
  background-color: rgb(233, 233, 233);
}
