.homepage {
  min-height: 100vh;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 74px;

  .carousel-caption {
    bottom: 50% !important;
    text-align: left;
    transform: translateY(50%);
  }

  // Content
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;

    // Container about title & youtube video
    &-left {
      min-width: 700px;

      @media only screen and (max-width: 800px) {
        min-width: 310px;
        width: 90%;
      }
      width: 70%;
      flex-grow: 3;
    }
    // Container actuality thumbnails
    &-right {
      @media only screen and (max-width: 800px) {
        min-width: 310px;
        width: 90%;
      }
      min-width: 400px;
      width: 20%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-grow: 2;
      justify-content: center;
    }
  }
  // About section
  &-about {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      color: #0db539;
      font-size: 22px;
      line-height: 20px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    &-paragraph {
      @media only screen and (max-width: 800px) {
        width: 90%;
        height: 200px;
      }

      width: 70%;
      margin-bottom: 50px;
      height: 250px;
      overflow-y: scroll;
    }
  }
}

.blurred {
  filter: blur(40px);
}
.block-scrolling {
  overflow: hidden;
}
.button-partners {
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 11px -1px #ececec;
  border: 1px solid rgb(209, 209, 209);
}
.partner-button-container {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
