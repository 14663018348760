.about-carousel-text-container {
  padding: 20px;
  width: 50%;

  background-color: #fff;
  @media only screen and (max-width: 1305px) {
    width: 100%;
  }
  @media only screen and (max-width: 590px) {
    padding: 20px 10px;
  }
  @media only screen and (max-width: 420px) {
    background-color: initial;
  }
}

.about-carousel-text-title {
  width: 150px;
  background: #0db539;
  margin: 5px 0 20px 0;

  h3 {
    color: #ffffff;
    font-size: 24px;
    line-height: 42px;
    font-weight: 600;
    font-family: 'Raleway';
  }
}
.about-carousel-text-ul {
  width: 100%;
  text-align: left;
  h3 {
    color: #27282c;
    font-size: 24px;
    font-weight: 600;
    margin: 40px 0;
    text-transform: uppercase;
  }
  ul {
    list-style-type: initial;
  }
}
