.bootstrap-form {
  font-family: 'Raleway';
}

.logo-upload-container {
  @media only screen and (max-width: 550px) {
    display: flex;
    flex-direction: column;
  }
}

.bootstrap-form-select-currency {
  display: flex;
  flex-direction: row;
  &-select {
    width: 25% !important;
    min-width: 120px;
  }
  &-amount {
    width: 90% !important;
  }
}
